var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[(_vm.isLoading)?_c('div',{staticClass:"loader"},[_c('CSpinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"color":"primary","grow":""}})],1):_vm._e(),_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.$t("management.statistics.cancelled_orders.title"))+" "),_c('CButton',{staticClass:"float-right",attrs:{"color":"primary","size":"sm","square":""},on:{"click":_vm.generateCSV}},[_vm._v(" "+_vm._s(_vm.$t("buttons.generate_csv"))+" ")])],1),_c('CCardBody',[_c('CRow',{attrs:{"align-horizontal":"center"}},[_c('CCol',{attrs:{"sm":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t("admin.date")))]),_c('v-date-picker',{attrs:{"model-config":_vm.modelConfig,"is-range":"","timezone":"UTC"},on:{"dayclick":_vm.getStatistics},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, togglePopover }){return [_c('CInput',{attrs:{"value":`${inputValue.start} — ${inputValue.end}`,"readonly":""},on:{"click":togglePopover}})]}}]),model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1),_c('CRow',{attrs:{"align-horizontal":"center"}},[_c('CCol',{attrs:{"sm":"6"}},[_c('CDataTable',{attrs:{"fields":_vm.fieldsTotal,"items":_vm.items[0],"noItemsView":{
            noResults: _vm.$t('admin.no_results'),
            noItems: _vm.$t('admin.no_items')
          },"border":"","hover":"","striped":""}})],1)],1),_c('CRow',{attrs:{"align-horizontal":"center"}},[_c('CCol',{attrs:{"sm":"6"}},[_c('CDataTable',{attrs:{"fields":_vm.fieldsSeparate,"items":_vm.items[1],"noItemsView":{
            noResults: _vm.$t('admin.no_results'),
            noItems: _vm.$t('admin.no_items')
          },"border":"","hover":"","striped":""}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }