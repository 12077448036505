<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("management.statistics.balances.title") }}
    </CCardHeader>
    <CCardBody>
      <CRow align-horizontal="center">
        <CCol sm="3">
          <label>{{ $t("admin.date") }}</label>
          <v-date-picker
            timezone="UTC"
            v-model="range"
            :model-config="modelConfig"
            is-range
            @dayclick="getStatistics"
          >
            <template #default="{ inputValue, togglePopover }">
              <CInput
                :value="`${inputValue.start} — ${inputValue.end}`"
                @click="togglePopover"
                readonly
              />
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <label style="font-size: 25px;">{{
        $t("management.statistics.balances.positive")
      }}</label>
      <CDataTable
        :items="table.items_p"
        :fields="table.fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        v-for="(table, index) in tables_p"
        :key="index"
        border
        striped
        hover
      />
      <label style="font-size: 25px;">{{
        $t("management.statistics.balances.negative")
      }}</label>
      <CDataTable
        :items="table.items_n"
        :fields="table.fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        v-for="(table, index) in tables_n"
        :key="index"
        border
        striped
        hover
      />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      range: {
        start: new Date(
          new Date(
            `${new Date().getFullYear()}/${new Date().getMonth()+1}/${new Date().getDate()}`
          ).setHours(0, 0, 0, 0)
        ),
        end: new Date(new Date().setHours(23, 59, 59, 999))
      },
      modelConfig: {
        start: {
          timeAdjust: "00:00:00"
        },
        end: {
          timeAdjust: "23:59:59"
        }
      },
      tables_p: [],
      tables_n: []
    };
  },

  created() {
    this.getStatistics();
  },

  methods: {
    getStatistics() {
      this.isLoading = true;
      if (this.range.start == null) {
        this.isLoading = false;
        return;
      }
      this.$http
        .get(`/api/accountant/statistics/balances`, {
          params: {
            from: this.range.start.toISOString(),
            to: this.range.end.toISOString()
          }
        })
        .then(response => {
          this.tables_p = [];
          this.tables_n = [];
          response.data.data.forEach(data => {
            const fields = data[0].map((label, key) => ({
              key: key.toString(),
              label
            }));
            const items_p = [data[1][0]];
            const items_n = [data[1][1]];
            this.tables_p.push({ fields, items_p });
            this.tables_n.push({ fields, items_n });
          });

          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("management.statistics.balances.title")
    };
  }
};
</script>
