<template>
  <CCard>
    <div v-if="isLoading" class="loader">
      <CSpinner color="primary" grow style="width:4rem; height:4rem;" />
    </div>

    <CCardHeader>
      {{ $t("management.statistics.purchased_orders.title") }}

      <CButton
        class="float-right"
        color="primary"
        size="sm"
        square
        @click="generateCSV"
      >
        {{ $t("buttons.generate_csv") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CRow align-horizontal="center">
        <CCol sm="3">
          <label>{{ $t("admin.date") }}</label>
          <v-date-picker
            v-model="range"
            :model-config="modelConfig"
            is-range
            timezone="UTC"
            @dayclick="getStatistics"
          >
            <template #default="{ inputValue, togglePopover }">
              <CInput
                :value="`${inputValue.start} — ${inputValue.end}`"
                readonly
                @click="togglePopover"
              />
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow align-horizontal="center">
        <CCol sm="6">
          <CDataTable
            :fields="fieldsTotal"
            :items="items[0]"
            :noItemsView="{
              noResults: $t('admin.no_results'),
              noItems: $t('admin.no_items')
            }"
            border
            hover
            striped
          />
        </CCol>
      </CRow>
      <CRow align-horizontal="center">
        <CCol sm="6">
          <CDataTable
            :fields="fieldsSeparate"
            :items="items[1]"
            :noItemsView="{
              noResults: $t('admin.no_results'),
              noItems: $t('admin.no_items')
            }"
            border
            hover
            striped
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import FileSaver from "file-saver";

export default {
  data() {
    return {
      isLoading: true,
      range: {
        start: new Date(new Date().setHours(0, 0, 0, 0)),
        end: new Date(new Date().setHours(23, 59, 59, 999))
      },
      items: [],
      modelConfig: {
        start: {
          timeAdjust: "00:00:00"
        },
        end: {
          timeAdjust: "23:59:59"
        }
      },
      fieldsTotal: [
        {
          key: "0",
          label: "Date"
        },
        {
          key: "1",
          label: "Total"
        },
        {
          key: "2",
          label: "Amex"
        },
        {
          key: "3",
          label: "CC"
        }
      ],
      fieldsSeparate: [
        {
          key: "0",
          label: "Date"
        },
        {
          key: "1",
          label: "Order ID"
        },
        {
          key: "2",
          label: "Total"
        },
        {
          key: "3",
          label: "Type"
        },
        {
          key: "4",
          label: "Status"
        }
      ]
    };
  },

  created() {
    this.getStatistics();
  },

  methods: {
    getStatistics() {
      this.isLoading = true;
      this.$http
        .get(`/api/accountant/statistics/purchased-orders`, {
          params: {
            from: this.range.start.toISOString(),
            to: this.range.end.toISOString(),
            is_pickup: this.$route.meta.pickup || false
          }
        })
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        });
    },
    generateCSV() {
      this.isLoading = true;

      this.$http
        .get(`/api/accountant/statistics/purchased-orders/csv`, {
          params: {
            from: this.range.start.toISOString(),
            to: this.range.end.toISOString()
          },
          responseType: "blob"
        })
        .then(response => {
          FileSaver.saveAs(new Blob([response.data]), "purchased-orders.csv");
          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("management.statistics.purchased_orders.title")
    };
  }
};
</script>
