var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[(_vm.isLoading)?_c('div',{staticClass:"loader"},[_c('CSpinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"color":"primary","grow":""}})],1):_vm._e(),_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.$t("management.statistics.balances.title"))+" ")]),_c('CCardBody',[_c('CRow',{attrs:{"align-horizontal":"center"}},[_c('CCol',{attrs:{"sm":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t("admin.date")))]),_c('v-date-picker',{attrs:{"timezone":"UTC","model-config":_vm.modelConfig,"is-range":""},on:{"dayclick":_vm.getStatistics},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, togglePopover }){return [_c('CInput',{attrs:{"value":`${inputValue.start} — ${inputValue.end}`,"readonly":""},on:{"click":togglePopover}})]}}]),model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1),_c('label',{staticStyle:{"font-size":"25px"}},[_vm._v(_vm._s(_vm.$t("management.statistics.balances.positive")))]),_vm._l((_vm.tables_p),function(table,index){return _c('CDataTable',{key:index,attrs:{"items":table.items_p,"fields":table.fields,"noItemsView":{
        noResults: _vm.$t('admin.no_results'),
        noItems: _vm.$t('admin.no_items')
      },"border":"","striped":"","hover":""}})}),_c('label',{staticStyle:{"font-size":"25px"}},[_vm._v(_vm._s(_vm.$t("management.statistics.balances.negative")))]),_vm._l((_vm.tables_n),function(table,index){return _c('CDataTable',{key:index,attrs:{"items":table.items_n,"fields":table.fields,"noItemsView":{
        noResults: _vm.$t('admin.no_results'),
        noItems: _vm.$t('admin.no_items')
      },"border":"","striped":"","hover":""}})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }