<template>
  <div>
    <CRow>
      <CCol sm="2">
        <label>{{ $t("admin.date") }}</label>
        <v-date-picker
          timezone="UTC"
          v-model="range"
          placeholder="Select a Date"
          :model-config="modelConfig"
          is-range
        >
          <template #default="{ inputValue, togglePopover }">
            <CInput
              :value="`${inputValue.start} — ${inputValue.end}`"
              @click="togglePopover"
              readonly
            />
          </template>
        </v-date-picker>
      </CCol>
      <CCol sm="2">
        <CInput :label="$t('admin.search_by_user_id')" v-model="search.id" />
      </CCol>
      <CCol sm="2">
        <CInput
          :label="$t('admin.search_by_user_name')"
          v-model="search.full_name"
        />
      </CCol>
      <CCol sm="2">
        <CInput :label="$t('admin.search_by_email')" v-model="search.email" />
      </CCol>
      <CCol sm="2">
        <CInput :label="$t('admin.search_by_phone')" v-model="search.phone" />
      </CCol>
      <CCol sm="2" class="col-sm-2 d-flex align-items-center mt-2">
        <CButton
          color="primary"
          square
          block
          class="float-right"
          @click="getStatistics()"
        >
          {{ $t("filters.title") }}
        </CButton>
      </CCol>
    </CRow>
    <CCard>
      <div class="loader" v-if="isLoading">
        <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
      </div>
      <CCardHeader>
        {{ $t("management.statistics.user_balances.title") }}
      </CCardHeader>
      <CCardBody>
        <CDataTable
          v-for="(table, index) in tables"
          :items="table.items"
          :fields="table.fields"
          :noItemsView="{
            noResults: $t('admin.no_results'),
            noItems: $t('admin.no_items')
          }"
          :key="index"
          border
          striped
          hover
          :sorterValue="sorterValue"
          :sorter="{ external: true, resetable: true }"
          @update:sorter-value="getStatistics($event)"
          @pagination-change="getStatistics"
        >
          <template #client_id="{item}">
            <td class="py-2">
              <a :href="'/admin/users/' + item.client_id + '/show'">{{
                item.client_id
              }}</a>
            </td>
          </template>
          <template #full_name="{item}">
            <td class="py-2">
              <a :href="'/admin/users/' + item.client_id + '/show'">{{
                item.full_name
              }}</a>
            </td>
          </template>
          <template #amount="{item}">
            <td class="py-2">
              {{ item.amount }}
            </td>
          </template>
          <template #balance_create="{item}">
            <td class="py-2">
              {{ item.balance_create }}
            </td>
          </template>
        </CDataTable>
        <CPagination
          :activePage="pagination.current_page"
          :pages="pagination.last_page"
          align="center"
          @update:activePage="getStatistics($event)"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      range: {
        start: null,
        end: null
      },
      pagination: {},
      sorterValue: { column: "3", asc: false },
      search: {
        id: null,
        full_name: null,
        email: null,
        phone: null
      },
      modelConfig: {
        start: {
          timeAdjust: "00:00:00"
        },
        end: {
          timeAdjust: "23:59:59"
        }
      },
      sortBy: "balance_create",
      sortDirection: "desc",
      tables: []
    };
  },

  created() {
    this.getStatistics();
  },

  methods: {
    getStatistics(page = 1) {
      this.isLoading = true;
      if (this.range.start == null) {
        this.isLoading = false;
        return;
      }
      if (typeof page.column != "undefined") {
        switch (page.column) {
          case "client_id":
            this.sortBy = "id";
            break;
          case "full_name":
            this.sortBy = "first_name";
            break;
          case "amount":
            this.sortBy = "amount";
            break;
          case "balance_create":
            this.sortBy = "balance_create";
            break;
        }
        this.sortDirection = page.asc ? "asc" : "desc";
        this.sorterValue = page;
        page = 1;
      }
      const sortBy = this.sortBy;
      const sortDirection = this.sortDirection;
      this.$http
        .get(`/api/accountant/statistics/user_balances`, {
          params: {
            page,
            sortBy,
            sortDirection,
            ...this.search,
            from: this.range.start ? this.range.start.toISOString() : null,
            to: this.range.end ? this.range.end.toISOString() : null
          }
        })
        .then(response => {
          this.tables = [];
          this.pagination = response.data.meta;
          response.data.data.forEach(data => {
            const fields = Object.keys(data[0]).map(key => ({
              key: key.toString(),
              name: data[0][key]
            }));
            const items = data[1];

            this.tables.push({ fields, items });
          });

          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("management.statistics.user_balances.title")
    };
  }
};
</script>
