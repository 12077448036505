<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("management.statistics.user_balances.title") }}
    </CCardHeader>
    <CCardBody>
      <CRow align-horizontal="center">
        <CCol sm="3">
          <label>{{ $t("admin.date") }}</label>
          <v-date-picker
            timezone="UTC"
            v-model="date"
            :model-config="modelConfig"
            @dayclick="getStatistics"
          >
            <template #default="{ inputValue, togglePopover }">
              <CInput
                :value="`${inputValue}`"
                @click="togglePopover"
                readonly
              />
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
      <label style="font-size: 20px;">{{
        $t("management.statistics.balances.positive")
      }}</label>
        </CCol>
        <CCol>
          <label style="font-size: 20px;">{{
              $t("management.statistics.balances.negative")
            }}</label>
        </CCol>
      </CRow>
      <CRow>
        <CCol style="font-size: 25px;">
      {{tables_p}}
        </CCol>
        <CCol style="font-size: 25px;">
      {{tables_n}}
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      date: new Date(new Date().setHours(23, 59, 59, 999)),
      items: [],
      modelConfig: {
        date: {
          timeAdjust: "23:59:59"
        }
      },
      tables_p: 0,
      tables_n: 0
    };
  },

  created() {
    this.getStatistics();
  },

  methods: {
    getStatistics() {
      this.isLoading = true;
      this.$http
        .get(`/api/accountant/statistics/balancesondate`, {
          params: {
            to: this.date.toISOString()
          }
        })
        .then(response => {
          this.tables_p = response.data.data[0];
          this.tables_n = response.data.data[1];

          this.isLoading = false;
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("management.statistics.user_balances_on_date.title")
    };
  }
};
</script>
